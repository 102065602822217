export function findNode(s) {
    return document.querySelector(s)
}

export function findNodes(s) {
    return document.querySelectorAll(s)
}

export function useNode(s, callback) {
    let node = findNode(s);
    if (node === null) return null;
    callback(node);
    return node;
}

export function useNodes(s, callback) {
    let nodes = findNodes(s);
    if (nodes.length === 0) return;
    nodes.forEach(callback);
}

(() => {
    'use strict'

    const storedTheme = localStorage.getItem('theme')

    const getPreferredTheme = () => {

        if (document.documentElement.dataset.themeForced !== undefined) {
            return document.documentElement.dataset.bsTheme;
        }

        if (storedTheme) {
            return storedTheme
        }

        return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    }

    const setTheme = function (theme) {

        if (document.documentElement.dataset.bsTheme === undefined) {
            theme = "light";
        }

        if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.setAttribute('data-bs-theme', 'dark')
        } else {
            document.documentElement.setAttribute('data-bs-theme', theme)
        }

        document.querySelector('.set-theme.active')?.classList.remove("active");
        document.querySelector('.set-theme[data-theme=' + theme + ']')?.classList.add("active");
        document.cookie = "theme=" + theme + "; max-age=31536000; path=/";
    }

    setTheme(getPreferredTheme())

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
        if (storedTheme !== 'light' || storedTheme !== 'dark') {
            setTheme(getPreferredTheme())
        }
    })

    window.addEventListener('DOMContentLoaded', () => {
        document.querySelectorAll('.set-theme')
            .forEach(toggle => {
                toggle.addEventListener('click', () => {
                    const theme = toggle.getAttribute('data-theme')
                    localStorage.setItem('theme', theme)
                    setTheme(theme)
                })
            })
    })
})()
