import { useNode } from "../global";
import { onDomReady } from "../onDomReady";

let isBusy = false;

export const initialize = function () {
    console.log("init is");

    useNode("#snippet-products-", function (box) {
        document.addEventListener("scroll", function () {

            if(isBusy) return;

            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 50) {
                isBusy = true;
                console.log("next page loaded");
            }
        });
    });
};

export const ublockNextLoading = function() {
    isBusy = false;
}

onDomReady(initialize);