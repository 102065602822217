import { findNode, useNode } from "../global";
import { onDomReady } from "../onDomReady";

export const initialize = function() {

    console.log("ac init");

    useNode(".ac-box", function(acBox) {
        //let head = findNode("header");
        let ac = findNode(".ac-box .ac");

        if (acBox.innerHTML.trim() === "") {
            acBox.classList.remove("show");
            acBox.style.backgroundImage = null;
            //head.classList.remove("ac");
        } else {
            acBox.style.backgroundImage = 'url(' + ac.dataset.acImg + ')';
            acBox.classList.add("show");
            //head.classList.add("ac");
        }

        setTimeout(() => {
            window.dispatchEvent(new CustomEvent("scroll"));
        }, 50);
    });
};

onDomReady(initialize);