import { useNode } from "./global";
import { onDomReady } from "./onDomReady";

var categoriesOpen = false;

export const initialize = function() {
    console.log("categories");

    if(document.querySelector(".categories .open.active.final")) {
        useNode("#offCategoriesToggler", function(el) {
            if(document.querySelector("#offCategories.offcanvas.show"))
                el.click();
        });
    }
};

onDomReady(initialize);