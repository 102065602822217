import {CookieConsent} from 'vanilla-cookieconsent'
import {onDomReady} from "./onDomReady";

export const initialize = function () {

    console.log("cc init");

    let cc = initCookieConsent();

    cc.run({
        current_lang: 'en',
        autoclear_cookies: true,                   // default: false
        page_scripts: true,                        // default: false

        // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        // auto_language: null                     // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: true,                   // default: true
        // remove_cookie_tables: false             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        onFirstAction: function (user_preferences, cookie) {
            // callback triggered only once
        },

        onAccept: function (cookie) {
            // ...
        },

        onChange: function (cookie, changed_preferences) {
            // ...
        },

        languages: {
            'en': {
                consent_modal: {
                    title: 'Používáme cookies!',
                    description: 'Bazu.cz používá Cookies k zajištění fungování stránky a k pochopení, jak návštěvníci náš web používají, díky těmto informacím dokážeme přizpůsobit web Vašim potřebám. <button type="button" data-cc="c-settings" class="cc-link">Nastavit cookies</button>',
                    primary_btn: {
                        text: 'Povolit vše',
                        role: 'accept_all'
                    },
                },
                settings_modal: {
                    title: 'Nastavení Cookies',
                    save_settings_btn: 'Uložit volby',
                    accept_all_btn: 'Povolit vše',
                    reject_all_btn: 'Odmítnout vše',
                    close_btn_label: 'Zavřít',
                    cookie_table_headers: [
                        {col1: 'Název'},
                        {col2: 'Doména'},
                        {col3: 'Platnost'},
                        {col4: 'Popis'}
                    ],
                    blocks: [
                        {
                            title: 'Použití Cookie 📢',
                            description: 'Používáme cookies k zajištění fungování základních funkcí webu a k zajištění správného fungování reklam. Vždy si můžete zvolit, jaké cookies povolíte.'
                        }, {
                            title: 'Nutné cookies',
                            description: 'Bez těchto cookies web nemůže fungovat',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            }
                        }, {
                            title: 'Analytické cookies',
                            description: 'Tyto cookies nám umožňují vědět, jak naši návštěvníci používají web a my ho můžeme přizpůsobit přímo jejich potřebám.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            },
                        },
                        {
                            title: 'Více informací',
                            description: 'Pro více informací ohledně cookies nás můžete kontaktovat na cookies@bazu.cz',
                        }
                    ]
                }
            }
        }
    });
};

onDomReady(initialize);
