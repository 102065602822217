import {findNode, useNode} from "./global";
import {onDomReady} from "./onDomReady";

export const initialize = () => {

    useNode("#quicksearch", function (qs) {
        if (qs.dataset.init) return;
        console.log("quick search init");

        qs.addEventListener("click", function (event) {
            console.log("qs clicked");
            let btn = findNode("#search-btn");
            btn.dispatchEvent(new MouseEvent("click"));
        });
        qs.dataset.init = "1";
    });
};

onDomReady(initialize);
