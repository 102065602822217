export class Debounce {

    timeoutId;

    stop() {
        if (this.timeoutId)
            clearTimeout(this.timeoutId);
    }

    debounce(callback, time) {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => {
            console.log("trigger");
            callback();
        }, time);
    };
}
