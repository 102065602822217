import * as bootstrap from "bootstrap5";
import { findNode, useNode } from "../global";
import { onDomReady } from "../onDomReady";

var filtersOpen = false;

export const initialize = function() {
    console.log("params");

    let filters = findNode("#offFilters.parameters");
    let isEmpty = filters === null || filters.innerHTML.trim() === "";
    console.log("params >" + isEmpty);
    findNode("#offFiltersToggler").classList.toggle("d-none", isEmpty);
    if (!isEmpty)
        findNode("#offFilters").classList.toggle("show", false);

    useNode("#offFilters", function(filters) {

        if (filtersOpen) filtersOpen = false;
    
        filters.addEventListener("show.bs.offcanvas", event => filtersOpen = true);
        filters.addEventListener("hide.bs.offcanvas", event => filtersOpen = false);
        let oc = new bootstrap.Offcanvas(filters);

        if (filtersOpen) filters.classList.toggle("show");
        filtersOpen ? oc.show() : oc.hide();
    });

    useNode(".filter-preview", function(fp) {
        fp.addEventListener("click", function () {
            console.log("close");
            findNode("#offFiltersToggler").dispatchEvent(new MouseEvent("click"));
        });

        let pc = findNode(".pr-count").innerText;
        fp.innerText = fp.dataset.text.replace("%", pc);
    });
}

onDomReady(initialize);
