//import '../scss/global.scss'
import '../scss/front.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

import './global.js';
import * as bootstrap from 'bootstrap5'
import {findNode, findNodes, useNode} from "./global";
import {spinnerExtension} from './naja-spinner';
import naja from "naja";
import {initialize as initParameters} from './components/parameters'
import {initialize as initCategories} from './categories'
import {initialize as initQuicksearch} from './quicksearch'
import {initialize as initCookies} from './cookieconsent'
import {initialize as initAds} from './components/ads'
import {initialize as initIS, ublockNextLoading} from './components/infinite-scroll';
import {Debounce} from './debounce';


naja.registerExtension(spinnerExtension);

const initProducts = function () {
    document.querySelectorAll(".range").forEach((cont) => {
        let rangeMin = parseInt(cont.dataset.min);
        let range = cont.querySelector(".range-selected");
        let rangeInput = cont.querySelectorAll(".range-input input");
        let rangePrice = cont.querySelectorAll(".range-price-from input, .range-price-to input");

        rangeInput.forEach((input) => {
            input.addEventListener("input", (e) => {
                let minRange = parseInt(rangeInput[0].value);
                let maxRange = parseInt(rangeInput[1].value);

                if (maxRange - minRange < rangeMin) {
                    if (e.target.className === "min") {
                        rangePrice[0].value = rangeInput[0].value = maxRange - rangeMin;
                    } else {
                        rangeInput[1].value = minRange + rangeMin;
                    }
                } else {
                    rangePrice[0].value = minRange;
                    rangePrice[1].value = maxRange;
                    range.style.left = (minRange / rangeInput[0].max) * 100 + "%";
                    range.style.right = 100 - (maxRange / rangeInput[1].max) * 100 + "%";
                }
            });

            input.addEventListener("change", (e) => {
                let _ = naja.uiHandler.submitForm(e.target.form);
            });

            range.style.left = (parseInt(rangeInput[0].value) / rangeInput[0].max) * 100 + "%";
            range.style.right = 100 - (parseInt(rangeInput[1].value) / rangeInput[1].max) * 100 + "%";
        });
    });
};

const hideElementIfEmpty = (id) => {
    let el = document.getElementById(id);
    if (el === null) return;
    el.classList.toggle("d-none", (el.innerHTML.trim() === ""));
}

const initAutosubmitControls = () => {
    document.querySelectorAll("input.autosubmit").forEach(function (e) {
        if (e.attributes["type"].value == "number") return;

        if (e.attributes["type"].value == "checkbox") {
            e.addEventListener("change", (event) => {
                naja.uiHandler.submitForm(event.target.form);
            });
        } else {
            let timer = 0;
            e.addEventListener('keyup', (event) => {
                let val = event.target.value;
                let dv = event.target.dataset.v;
                if (dv === val) {
                    event.preventDefault();
                    return;
                } else {
                    event.target.dataset.v = val;
                }

                clearTimeout(timer)
                timer = setTimeout(function () {
                    //document.querySelector(".category-search").setAttribute("readonly", true);
                    naja.uiHandler.submitForm(event.target.form);
                }, 250)
            });
        }

        e.dataset.v = e.value;
        e.classList.remove("autosubmit");
    });

    document.querySelectorAll("select.autosubmit, input[type=number].autosubmit").forEach(function (e) {
        e.addEventListener('change', (event) => {
            naja.uiHandler.submitForm(event.target.form);
        });
        e.classList.remove("autosubmit");
    });
};

/*var onScrollBusy = false;

function loadMoreProducts(page, url) {
    onScrollBusy = true;
    naja.makeRequest("get", url, {page: page});
    onScrollBusy = false;
}*/
/*
var throttleTimer;
const throttle = (callback, time) => {
    if (throttleTimer) return;

    throttleTimer = true;

    setTimeout(() => {
        callback();
        throttleTimer = false;
    }, time);
};*/

/*function shouldLoadMoreProducts(el) {
    if (el.dataset.lm == 0) return false;

    return Math.abs(window.scrollY + window.innerHeight) > el.offsetTop - 150;
}*/

/*const handleInfiniteScroll = () => {
    const ap = findNode(".after-products");
    if (ap === null) return;

    throttle(() => {
        const endOfPage = shouldLoadMoreProducts(ap);

        if (endOfPage) {
            let u = ap.dataset.u;
            ap.remove();
            naja.makeRequest("get", u);
        }
    }, 250);
};*/


/*window.onload = function () {
    window.addEventListener("scroll", handleInfiniteScroll);
}*/

/*const onScroll = function () {
    console.log("scroll");
    let acBox = findNode(".ac-box");
    let header = findNode("body > header");
    header.classList.toggle("scrolled", window.scrollY > 1 + (acBox.offsetHeight - header.offsetHeight));
}*/

const initContactButton = function () {
    document.querySelectorAll(".contact-button").forEach(function (a) {
        a.addEventListener("click", function (e) {
            e.target.outerHTML = atob(e.target.dataset.contact);
        });
    });
};

//window.addEventListener('scrollend', onScroll);
window.addEventListener('load', function () {

    useNode("#ocSearch", function (searchOffcanvas) {
        searchOffcanvas.addEventListener('shown.bs.offcanvas', event => {
            event.target.querySelector("input[name=fulltext]").focus();
        });
    })

    naja.uiHandler.selector = ':not([href=\'#\']):not(.noajax)';
    naja.initialize();
    this.window.naja = naja;

    initProducts();
    initAutosubmitControls();
    initContactButton();
    initQuicksearch();

    naja.snippetHandler.addEventListener('beforeUpdate', (event) => {
    });

    naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        if (event.detail.snippet.id === 'snippet-parameters-') {
            initParameters();
        }

        if (event.detail.snippet.id === 'snippet-categories-') {
            initCategories();
        }

        if (event.detail.snippet.id === 'snippet--ac') {
            initAds();
        }

        initAutosubmitControls();

        if (event.detail.snippet.id === 'snippet--content') {
            initProducts();
            initContactButton();
            initQuicksearch();
            window.scrollTo(0, 0);
        }
        initQuicksearch();

        if (event.detail.snippet.id === 'snippet-products-') {
            /*let ap = findNode(".after-products");
            if (ap !== null) {
                ap.dataset.lm = "1";
            }*/
            ublockNextLoading();
        }
    });

    const mainContent = document.querySelector('body');
    naja.addEventListener('start', () => mainContent.classList.add('loading'));
    naja.addEventListener('complete', () => mainContent.classList.remove('loading'));
    naja.addEventListener('error', async (event) => {
        let response = event.detail.response;
        let snippets = (await response.json()).snippets;

        if (response.status === 404 && snippets) {
            naja.snippetHandler.updateSnippets(snippets);
        }
    });

    let fulltextDebounce = new Debounce();
    document.querySelector("input[name=fulltext]").addEventListener("keyup", function (e) {
        let value = e.target.value.trim();

        if (e.key === "Enter") {
            e.preventDefault();
            location.assign(e.target.form.dataset.url.replace("/x", "/" + value));
            return;
        }

        let setResults = function (data) {
            data = data.trim();
            let sb = document.querySelector("div.search-autocomplete");

            if (sb === null) {
                sb = document.createElement("div");
                sb.classList.add("search-autocomplete", "bg-body", "row", "gx-0");
                e.target.closest("form").append(sb);
            }

            sb.innerHTML = data;
            sb.classList.toggle("d-none", data === "");
        };

        fulltextDebounce.debounce(function () {
            fetch(e.target.dataset.api + e.target.value)
                .then((response) => response.text())
                .then(function (data) {
                    setResults(data);
                });
        }, 200);

        if (value === "" || value.length < 3) {
            setResults("");
            fulltextDebounce.stop();
            return;
        }
    });
});
